.cal-div1 {
    min-height: 100vh;
    padding-top: 145px;
    padding-bottom: 90px;
    background-color: #000000;
}

.book-text {
  color: #FFF;
text-align: center;
font-family: Florensa Demo;
font-size: 86px;
font-style: normal;
font-weight: 400;
line-height: 90px; /* 104.651% */
text-transform: uppercase;
margin-bottom: 20px;
}

.book-div2 {
    margin-top: -10px;
}



@media screen and (max-width: 576px) {

 .cal-div {
        padding-top: 80px;
        padding-bottom: 75px;
    }

    .book-text {
        font-size: 42px;
        line-height: 50px;
    }

    .book-div2 {
        margin-top: 40px;
    }

}