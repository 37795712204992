.footer-div1 {
  padding: 0px;
}

.footer-div2 {
}

.footer-div3 {
  background: #111;
  /* mix-blend-mode: overlay; */
  width: 100%;
  height: 100%;
  padding-top: 120px;
  padding-bottom: 15px;
}

.footer-text1 {
  color: #d3d3d3;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-textdivs12 {
  display: flex;
  align-items: center;
}

.footer-text2 {
  color: #fff;
  font-family: Florensa Demo;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: 140px; /* 94.118% */
  text-transform: uppercase;
}

.footer-text2 span {
  color: #2eb25f;
  font-family: Florensa Demo;
  font-size: 140px;
  font-style: normal;
  font-weight: 400;
  line-height: 140px;
  text-transform: uppercase;
}

.footer-svg1 {
  width: 276px;
  height: 276px;
  flex-shrink: 0;
  border-radius: 276px;
  background: #2cc465;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
}

.footer-svgline1 {
  margin-top: 60px;
  margin-bottom: 60px;
  background: #979797;
  width: 100%;
  min-height: 0.6px;
}

.footer-agency-textdiv1 {
  column-gap: 60px;
}

.footer-agency-text1 {
  color: #666;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.footer-agency-ultext {
  list-style: none;
  padding-left: 0px;
  margin-top: 16px;
}

.footer-agency-ultext li a {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.footer-agency-ultext li a:hover {
  color: #fff !important;
  text-decoration: none !important;
}

.footer-agency-ultext1234 {
  list-style: none;
  padding-left: 0px;
  margin-top: 41px;
}

.footer-agency-ultext1234 li a {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.footer-agency-ultext1234 li a:hover {
  color: #fff !important;
  text-decoration: none !important;
}

.footer-email-text1 {
  color: #666;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer-email-textdiv1 {
  display: inline-flex;
  padding: 13px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 0.5px solid #fff;
  background: #092313;
}

.footer-email-text2 {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}

.footer-logo {
  margin-top: 23px;
}

.footer-svgline2 {
  margin-top: 60px;
  margin-bottom: 30px;
  background: #979797;
  width: 100%;
  min-height: 0.6px;
}

.copyright-text1 {
  color: rgba(211, 211, 211, 0.5);
  font-family: Poppins;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

@media screen and (max-width: 1199px) {
  .footer-text2 {
    font-size: 120px;
    line-height: 120px;
    text-transform: uppercase;
  }
}

@media screen and (max-width: 576px) {
  .footer-div3 {
    padding-top: 70px;
    padding-bottom: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-svg1 {
    width: 100px;
    height: 100px;
    margin-left: 26px;
  }

  .footer-svg1 svg {
    width: 70px !important;
    height: 70px !important;
    /* margin-left: 23px; */
  }

  .footer-svgline1 {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .footer-text2 {
    font-size: 44px;
    line-height: 50px;
  }

  .footer-text2 span {
    font-size: 44px;
    line-height: 50px;
  }

  .footer-text1 {
    font-size: 20px;
  }
  .footer-agency-textdiv1 {
    column-gap: 30px;
  }

  .footer-agency-ultext li a {
    font-size: 13px;
  }
  .footer-svgline2 {
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .footer-email-text1 {
    margin-top: 30px;
    color: #666;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

@media screen and (max-width: 380px) {
  .footer-text2 {
    font-size: 39px;
    line-height: 46px;
  }
}
