
.top-navbar-div1{
 padding-top: 11px;
 padding-bottom: 11px; 
background: #F4D846;

}

.top-navbar-text1{
 color: black;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

.top-navbar-text1 span{
color: black;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;

}

.top-navbar-text1 a{
 color: black;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align: center;
text-decoration: underline !important;
}

.navbar-div1{
position: absolute;
width: 100% !important;
z-index: 9;
margin-top: 18px;
}

.brain-navbar-logo1{
margin-left: 55px;
}

.navbar-logo1{
width: 95px;
margin-left: 50px;
}

.navbar-ul-div1{
    display: flex !important;
    justify-content: center;
    
}

.navbar-ul1{
    column-gap: 25px;
    margin-left: 66px;
}

.navbar-ul1 .nav-item .nav-link{
    color: #ECECEC;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.navbar-ul1 .nav-item .nav-link:hover{
    color: #ECECEC;
}

.navbar-contact-btn1{
    color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 160px;
height: 46px;
flex-shrink: 0;
border-radius: 23px;
background: linear-gradient(90deg, #40E570 0.13%, #84FFA8 99.68%);
display: flex;
align-items: center;
justify-content: center;
margin-right: 50px; 
}













.mobile-navbar-div1 {
    background-color: transparent !important;
    min-height: 90px;
    width: 100%;
    position: absolute;
    z-index: 999;
}

@media screen and (max-width: 991px) {
    /* .navbar-div1 {
        display: none;
    } */

    .mobile-navbar-div1 {
        display: block;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 99;
        margin-top: 55px;
    }
}

.mobile-nav-toogler {
    border: none;
}

.mobile-nav-item .nav-link {
     color: #FFF !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
    margin-top: 15px;
    margin-left: 0px !important;
}

.mobile-nav-item .nav-link:hover {
     color: #Fff !important;
 font-family: 'Poppins', sans-serif;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.mobile-navbar-div2 {
    /* background: #EAEFFF; */
    background-color: #000;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 250px;
    height: 90vh;
    position: absolute;
    left: 0px;
    top: 80px;
    transform: translateX(-110%);
    transition: all 0.5s linear;
    /* box-shadow: 1px 1px 10px rgb(131, 131, 131); */
}

.mobile-navbar-div2.show {
    /* background: #EAEFFF; */
    background-color: #000;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 40px;
    width: 250px;
    height: 90vh;
    position: absolute;
    left: 0px;
    top: 80px;
    transition: none;
    transform: translateX(0%) !important;
    transition: all 0.5s linear;
}

.mobile-nav-item.active .nav-link {
    color: black !important;
}


@media screen and (max-width: 576px) {

.nav-text1, .dark-nav-text1{

font-size: 16px;
}

.navbar-contact-btn1{
    color: #000;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: 110px;
height: 36px;
flex-shrink: 0;
border-radius: 23px;
background: linear-gradient(90deg, #40E570 0.13%, #84FFA8 99.68%);
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
margin-top: 5px; 
}


.top-navbar-text1{
 color: black;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 300;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

.top-navbar-text1 span{
color: black;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;

}
}


