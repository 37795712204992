@font-face {
  font-family: "Florensa Demo";
  src: url("../fonts/florensa-demo.regular.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

a {
  text-decoration: none !important;
}

.home-banner-div1 {
  background: #000;
  min-height: 113vh;
  padding-bottom: 100px;
  padding-top: 160px;
  position: relative;
  overflow: hidden;
}

.home-banner-svg1 {
  position: absolute;
  top: -160px;
  left: 200px;
  background: #5ed089;
  opacity: 0.25;
  filter: blur(88.5999984741211px);
  width: 500.448px;
  height: 278.294px;
  transform: rotate(168.656deg);
  flex-shrink: 0;
  z-index: 7;
}

.home-banner-svg2 {
  position: absolute;
  bottom: 20px;
  right: 70px;
  background: #5ed089;
  opacity: 0.25;
  filter: blur(88.5999984741211px);
  width: 350.448px;
  height: 238.294px;
  transform: rotate(168.656deg);
  flex-shrink: 0;
  z-index: 7;
}

.profile-banner-pics-12 {
  width: 45px;
  height: 43px;
  box-shadow: 0px 1.772px 2.481px 0px rgba(137, 137, 137, 0.25);
  border-radius: 100%;
}

.profile-banner-pics-22 {
  width: 45px;
  height: 43px;
  box-shadow: 0px 1.772px 2.481px 0px rgba(137, 137, 137, 0.25);
  margin-left: -15px;
  border-radius: 100%;
}

.profile-banner-pics-32 {
  width: 45px;
  height: 44px;
  box-shadow: 0px 1.772px 2.481px 0px rgba(137, 137, 137, 0.25);
  margin-left: -15px;
  border-radius: 100%;
}

.home-brand-text1 {
  color: #45e775;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 112.5% */
  margin-bottom: 0px !important;
  margin-left: 12px;
}

.home-banner-text1 {
  color: #fff;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
  margin-top: 30px;
}

.home-banner-text2 {
  color: #d3d3d3;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
  margin-top: 25px;
}

.home-banner-calldiv1 {
  margin-top: 40px;
}

.home-banner-callbtn1 {
  width: 192px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid #7efda2;
}

.home-banner-callbtn1::before {
  content: " ";
  position: absolute;
  overflow: hidden;
  background-color: black;
  width: 192px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  transform: translateX(-100%);
  transition: all 0.3s linear;
  z-index: -1;
  border: 1px solid #7efda2;
}

.home-banner-callbtn1:hover::before {
  transform: translateX(0);
}

.home-banner-callbtn1:hover {
  color: white;
}

.home-banner-callbtn1:hover svg.ml-2 path {
  fill: white;
}

.home-banner-callbtn2 {
  color: #fff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 200px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  border: 1px solid #7efda2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.home-banner-callbtn2::before {
  content: " ";
  position: absolute;
  overflow: hidden;
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  width: 200px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  transform: translateX(-100%);
  transition: all 0.3s linear;
  z-index: -1;
  border: 1px solid #7efda2;
}

.home-banner-callbtn2:hover::before {
  transform: translateX(0);
}

.home-banner-callbtn2:hover {
  color: black;
}

.home-banner-callbtn2:hover svg.ml-2 path {
  fill: black;
}

/* section ############ 2 */

.home-before-textdiv1 {
  display: inline-flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: #e5e5e5;
}

.home-before-text1 {
  color: #000;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
  text-transform: uppercase;
}

.home-after-textdiv1 {
  display: inline-flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: #c0ffd2;
}

.home-ecom-pic-div2 {
  row-gap: 20px;
  margin-top: -140px;
  position: relative;
  z-index: 10;
}

.ecom-pic-div1 img {
  padding-left: 8px;
  padding-right: 8px;
}

/* section ############ 3 */

.home-focused-div1 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-focused-text1 {
  color: #545454;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 10px; /* 177.778% */
}

.home-focused-text2 {
  color: #000;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 66px;
  font-style: normal;
  font-weight: 400;
  line-height: 70px; /* 106.061% */
  text-transform: uppercase;
}

.home-focused-text3 {
  color: #2a2a2a;
  text-align: center;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
}

/* section ############ 4 */

.home-cro-div1 {
  background: #000;
  padding-top: 70px;
  padding-bottom: 70px;
}

.home-cro-text1 {
  color: #dbdbdb;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 130.769% */
}

.home-cro-text2 {
  color: #fff;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
}

.home-cro-carddiv1 {
  margin-top: 80px;
  row-gap: 65px;
}

.home-cro-cardtext1 {
  color: #dcdcdc;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  margin-top: 20px;
  padding-right: 25px;
}

.home-play-pic-div1 {
  column-gap: 0px;
  margin-top: 45px;
}

.home-review-text1 {
  color: #dbdbdb;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 130.769% */
  margin-top: 120px;
}

@media screen and (min-width: 991px) {
  .home-play-pic {
    position: absolute;
    left: 30px;
    bottom: 25px;
    width: 100px;
  }
}

.review-audit-btn1 {
  width: 474px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  color: #000;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: 1px solid #7efda2;
}

.review-audit-btn1::before {
  content: " ";
  position: absolute;
  overflow: hidden;
  background: #000;
  width: 474px;
  height: 68px;
  flex-shrink: 0;
  border-radius: 63px;
  transform: translateX(-100%);
  transition: all 0.35s linear;
  z-index: -1;
  border: 1px solid #7efda2;
}

.review-audit-btn1:hover::before {
  transform: translateX(0);
}

.review-audit-btn1:hover {
  color: white;
}

.review-audit-btn1:hover svg.ml-2 path {
  fill: white;
}

.home-profiles-pic-div1 .home-profiles-pic-1 {
  /* max-width:300px !important; */
  height: 435px !important;
}

.home-profiles-pic-div1 .home-profiles-pic-2 {
  /* max-width:300px !important; */
  height: 430px !important;
}

/* section ############ 5 */

.home-pricing-div1 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.home-pricing-text1 {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 130.769% */
}

.home-pricing-text2 {
  color: #000;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
}

.pricing-plan-carddiv11 {
  margin-top: 60px !important;
}

.pricing-plan-carddiv11 div.col-lg-4 {
  min-height: 100% !important;
  position: relative;
}

.home-pricing-carddiv1 {
  border-radius: 15px;
  border: 1.061px solid #d1d1d1;
  background: #f6f6f6;
  box-shadow: 10px 14px 14px 0px rgba(191, 191, 191, 0.15);
  backdrop-filter: blur(80px);
  padding: 32px;
  height: 100%;
}

.home-pricing-cardtext1 {
  color: #000;
  font-family: Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 128% */
}

.home-pricing-cardtext2 {
  color: #5c5c5c;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
}

.home-pricing-cardbtn1 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 56px;
  border: 0.5px solid rgba(0, 0, 0, 0);
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  padding: 7px 31px;
  margin-bottom: 35px;
}

.home-pricing-cardtext3 {
  color: #5c5c5c;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.home-pricing-cardbtn2 {
  width: 100%;
  height: 54.056px;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 320% */
  border-radius: 61.211px;
  background: linear-gradient(93deg, #0b232b 1.76%, #10313d 68.29%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
}

.home-pricing-cardbtndiv2 {
  position: absolute !important;
  min-width: 100%;
  bottom: 32px;
  padding-right: 64px;
}

.home-pricing-carddiv112 {
  border-radius: 15px;
  border: 1.061px solid #d1d1d1;
  background: linear-gradient(180deg, #05232e 0%, #111 100%);

  box-shadow: 10px 14px 14px 0px rgba(191, 191, 191, 0.15);
  backdrop-filter: blur(80px);
  padding: 32px;
  height: 100%;
}

.home-pricing-cardtext112 {
  color: #fff;
  font-family: Helvetica;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 128% */
}

.home-pricing-cardtext212 {
  color: #c3c3c3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
}

.home-pricing-cardtext312 {
  color: #c3c3c3;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
}

.home-pricing-cardbtn212 {
  width: 100%;
  height: 54.056px;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 320% */
  border-radius: 61.211px;
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 27px;
}

.home-developer-div1 {
  border-radius: 15px;
  border: 1.061px solid #d1d1d1;
  background:#0D2831;
  box-shadow: 10px 14px 14px 0px rgba(191, 191, 191, 0.15);
  backdrop-filter: blur(80px);
  padding-top: 35px;
  padding-bottom: 35px;
  margin-top: 40px;
  padding-left: 70px;
  padding-right: 70px;
}

.home-developer-text1 {
  color: #ffff;
  font-family: Helvetica;
font-size: 26px;
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;

}

.home-developer-text2 {
  color: #fff;
  margin-top: -10px;
  font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 22px;
letter-spacing: 0em;
text-align: left;

}

/* .home-developer-btn1 {
  border-radius: 61.211px;
  border: 0.425px solid rgba(0, 0, 0, 0);
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  width: 278px;
  height: 54.056px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  margin-top: 20px;
} */


.development-icon-maindiv{
display: flex;
justify-content: center;
align-items: center;
column-gap: 10px;
}

.development-icon-div1{
width: 65px;
height: 65px;
background-color: #D9FFE4;
border-radius: 6.05px;
display: flex;
align-items: center;
justify-content: center;

}


.development-icon-div1 img{
  
}


/* section ############ 6 */

.home-topreport-text1{
 color: black;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

.home-topreport-text1 span{
  color: black;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

.home-report-div1 {
  background: #111;
  padding-top: 110px;
  padding-bottom: 110px;
}

.home-report-div121 div {
  position: sticky;
  top: 40px;
  overflow: hidden;
}

.home-report-text1 {
  color: #c4c4c4;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px; /* 145% */
}

.home-report-text2 {
  color: #fff;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px;
  text-transform: uppercase;
}

.home-report-text2 span {
  color: #2eb25f;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
}

.home-report-textdiv1 {
  margin-top: 65px;
  padding-top: 15px;
  position: sticky;
  top: 30px;
  background: #111;
}

.home-report-text3 {
  color: #fff;

  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
}

.home-report-text4 {
  color: #d9d9d9;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 64px; /* 246.154% */
}

.home-report-text5 {
  color: #dcdcdc;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}

/* section ############ 7 */

.home-user-div1 {
  padding-top: 90px;
  padding-bottom: 80px;
}

.home-user-rowdiv1 {
  row-gap: 50px;
}

.home-user-text1 {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.921px; /* 130.755% */
  letter-spacing: 0.577px;
  border-radius: 61.211px;
  border: 0.425px solid rgba(0, 0, 0, 0);
  background: linear-gradient(90deg, #40e570 0.13%, #84ffa8 99.68%);
  display: inline-flex;
  padding: 9px 22px;
  justify-content: center;
  align-items: center;
}

.home-user-text2 {
  color: #121212;
  font-family: Helvetica;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 100% */
  margin-top: 27px;
}

.home-user-ultext {
  margin-top: 17px;
  padding-left: 20px;
}

.home-user-ultext li {
  color: rgba(18, 18, 18, 0.75);
  font-family: Helvetica;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.099px; /* 138.564% */
  letter-spacing: 0.624px;
}

/* section ############ 8 */

.home-faqs-div1 {
  padding-top: 70px;
  padding-bottom: 110px;
}

.home-faqs-text1 {
  color: #000;
  text-align: center;
  font-family: Florensa Demo;
  font-size: 86px;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 104.651% */
  text-transform: uppercase;
}

.home-faq-div2 {
  margin-top: 80px;
}

.faq-div1 {
  border: 1.061px solid #d1d1d1;
  background: #f9f9f9;
  padding: 27px 30px;
}

.faq-question-div1 {
  justify-content: space-between;
}

.faq-text1 {
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.faq-text1:hover {
  cursor: pointer;
}

.faq-question-div1 svg {
  cursor: pointer;
}

.faq-answer-div1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq-text2 {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

/* section ############ 9 */

.home-tryaudit-div1 {
  border-radius: 15px;

  background-image: url(../Images/Rectangle\ 41061.png);
  margin-top: 70px;
}

.home-tryaudit-textdiv2 {
  padding-left: 70px;
}

.home-tryaudit-text1 {
  color: #b6b6b6;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px !important;
}

.home-tryaudit-text2 {
  color: #fff;
  font-family: Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px; /* 106.667% */
  margin-bottom: 0px !important;
}

.home-tryaudit-text2 span {
  color: #2eb25f;
  font-family: Helvetica;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
}

@media screen and (max-width: 1199px) {
  .home-banner-text1 {
    font-size: 68px;
    line-height: 72px;
  }
  .home-profiles-pic-div1 .home-profiles-pic-1 {
    max-width: 300px !important;
    height: 360px !important;
  }
}

@media screen and (max-width: 991px) {
  .home-profiles-pic-div1 .home-profiles-pic-1 {
    max-width: 100% !important;
    height: auto !important;
  }
}

/* // mobile queries */

@media screen and (max-width: 576px) {
  .home-banner-div1 {
    min-height: 115vh;
    padding-bottom: 100px;
    padding-top: 130px;
  }
  .home-banner-text1 {
    font-size: 48px;
    line-height: 0px;
  }

  .home-span1 {
    color: #fff;
    text-align: center;
    font-family: Florensa Demo;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px; /* 79.412% */
    text-transform: uppercase;
  }

  .home-span2 {
    color: #fff;
    font-family: Florensa Demo;
    font-size: 46px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px;
    text-transform: uppercase;
  }

  .home-span3 {
    color: #fff;
    text-align: center;
    font-family: Florensa Demo;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    text-transform: uppercase;
  }

  .home-span4 {
    color: #72f899;
    font-family: Florensa Demo;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    text-transform: uppercase;
  }

  .home-banner-text2 {
    font-size: 16px;
    line-height: 32px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .home-banner-callbtn1 {
    width: 100%;
    height: 58px;
    margin-right: 0px;
  }

  .home-banner-callbtn1::before {
    width: 100%;
    height: 58px;
  }
  .home-banner-callbtn2 {
    width: 100%;
    height: 58px;
    margin-left: 0px;
    margin-top: 17px;
    position: relative;
    z-index: 8;
  }
  .home-banner-callbtn2::before {
    width: 100%;
    height: 58px;
  }

  .home-ecom-pic-div2 {
    row-gap: 20px;
    margin-top: -150px;
    position: relative;
    z-index: 10;
  }

  .home-focused-div1 {
    padding-top: 80px;
    padding-bottom: 60px;
  }
  .home-focused-text2 {
    font-size: 38px;
    line-height: 43px;
  }

  .home-focused-text1 {
    font-size: 16px;
    line-height: 10px;
  }
  .home-focused-text3 {
    font-size: 18px;
    line-height: 28px;
    margin-top: 16px;
    margin-bottom: 28px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .home-cro-text1 {
    font-size: 18px;
    line-height: 10px;
  }
  .home-cro-text2 {
    font-size: 46px;
    line-height: 54px;
  }
  .home-cro-carddiv1 {
    margin-top: 70px;
    row-gap: 50px;
  }

  .home-cro-carddiv1 div.col-12 div {
    text-align: center;
  }

  .home-cro-cardtext1 {
    font-size: 16px;
    line-height: 27px;
    padding: 0px 27px !important;
  }

  .home-review-text1 {
    font-size: 18px;
    line-height: 33px;
    margin-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-play-pic-div1 {
    row-gap: 15px;
    margin-top: 45px;
  }

  .home-play-pic {
    left: 30px;
    bottom: 16px;
    width: 100px;
  }
  .review-audit-btn1 {
    width: 300px;
    height: 58px;
    margin-top: 38px;
  }

  .review-audit-btn1::before {
    width: 300px;
    height: 58px;
  }

  .home-play-pic-div1 div {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .home-pricing-text1 {
    font-size: 18px;
    line-height: 10px;
  }

  .home-pricing-text2 {
    font-size: 48px;
    line-height: 54px;
  }

  .pricing-plan-carddiv11 {
    margin-top: 45px !important;
    row-gap: 35px;
  }

  .home-pricing-carddiv1 {
    padding: 30px;
    height: 100%;
  }

  .home-pricing-cardtext1 {
    font-size: 48px;
  }

  .home-pricing-cardbtndiv2 {
    position: relative !important;
    min-width: 100%;
    bottom: 0px;
    padding-right: 0px;
  }

  .home-pricing-carddiv112 {
    padding: 30px;
    height: 100%;
  }

  .home-pricing-cardtext112 {
    font-size: 48px;
  }

  .home-pricing-cardbtn2 {
    margin-top: 40px;
  }
  .home-pricing-cardbtn212 {
    margin-top: 40px;
  }

  .home-developer-div1 {
    padding-top: 35px;
    padding-bottom: 35px;
    margin-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
  }


  .development-icon-div1 {
    width: 50px;
    height: 50px;
}

.development-icon-div1 img{
  width: 30px;
}

.home-developer-text1 {
    color: #ffff;
    font-family: Helvetica;
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
}

.home-developer-text2 {
    color: #ffff;
    font-size: 14px;
    line-height: 22px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 40px;
}

.home-topreport-text1{
 color: black;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

.home-topreport-text1 span{
  color: black;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-align: center;
margin-bottom: 0px;
}

  .home-report-text1 {
    font-size: 18px;
    line-height: 12px;
  }

  .home-report-text2 {
    font-size: 48px;
    line-height: 54px;
  }
  .home-report-text2 span {
    font-size: 48px;
    line-height: 54px;
  }
  .home-report-div1 {
    background: #111;
    padding-top: 80px;
    padding-bottom: 70px;
  }
  .home-report-text3 {
    font-size: 70px;
    line-height: 80px;
  }
  .home-report-text4 {
    font-size: 22px;
    line-height: 36px;
  }

  .home-report-text5 {
    font-size: 16.5px;
    line-height: 30px;
    padding-right: 15px;
  }

  .home-report-textdiv1 {
    margin-top: 35px;
    padding-top: 10px;
    position: sticky;
    top: 30px;
    background: #111;
  }

  .home-user-div1 {
    padding-top: 70px;
    padding-bottom: 60px;
  }

  .home-user-text2 {
    font-size: 33px;
    line-height: 39px;
    margin-top: 15px;
  }

  .home-user-ultext {
    padding-left: 16px;
  }

  .home-user-ultext li {
    font-size: 17px;
    line-height: 27px;
    padding-right: 15px;
  }
  .home-user-rowdiv1 {
    row-gap: 35px; 
  }

  .home-faqs-text1 {
    font-size: 40px;
    line-height: 45px;
  }

  .faq-text1 {
    font-size: 18px;
    margin-bottom: 0px !important;
  }
  .faq-div1 {
    border: 1.061px solid #d1d1d1;
    background: #f9f9f9;
    padding: 23px 20px;
  }
  .faq-text2 {
    font-size: 16px;
  }
  .home-faq-div2 {
    margin-top: 50px;
  }






  .for-mobile-reponsive-div1{
    margin-top: 70px;
  }
}

/* @media screen and (min-width: 500px){

.home-play-pic {
    left: 80px;
    bottom: 16px;
    width: 100px;
}
}

@media screen and (min-width: 450px){

.home-play-pic {
    left: 40px;
    bottom: 16px;
    width: 100px;
}
} */
